@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"















































































































.assessments-container

  &__tab
    color: $color-white
    margin-bottom: $size-xxs

    &.--active
      color: $color-white

      &.router-link-active::after
        position: absolute
        content: ''
        height: $size-xxs
        width: 100%
        bottom: - $size-xxs
        border-radius: $border-radius-l
        background: $color-white

    & ~ &
      margin-left: $size-m

.csat-button
  color: $color_white
  display: flex
  flex-direction: row
  &:hover
     cursor: pointer
